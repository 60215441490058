import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import '../i18n/i18n';
import { HomePageHead } from './homepage-head';
import { Footer } from '../footer/footer';
import { Header } from '../header/header';
import { TimeZoneTableWithContext } from '../timezone-table';
import { TZAbbrev } from '../timezone-table/interface';
import { Card, Col, Row, Space } from 'antd';
import { useHourAndMinDiff } from '../timezone-table/hooks/utils';

interface HomePageProps {
  language?: string;
  fromTimeZone?: TZAbbrev;
  fromTime?: string;
  toTimeZone?: TZAbbrev;
}

const formatTimeStrings = (input: string) => {
  // 使用正则表达式在数字和字母之间添加空格
  return input.replace(/(\d+)(am|pm)/gi, '$1 $2').toUpperCase();
}

export const HomePage = (props: HomePageProps) => {
    const { t, i18n } = useTranslation();
    const fromTimeZone = props.fromTimeZone || 'EST';
    const toTimeZone = props.toTimeZone || 'IST';
    const fromTime = formatTimeStrings(props.fromTime || '11 AM');
    const fromTimeZoneDisplayName = t(`tz.${fromTimeZone}.displayName`);
    const toTimeZoneDisplayName = t(`tz.${toTimeZone}.displayName`);
    const diff = useHourAndMinDiff(fromTimeZone, toTimeZone, true);
    
    const h1Content = props.fromTime ?
      `${formatTimeStrings(props.fromTime)} ${fromTimeZone} to ${toTimeZone} time zone converter`
      : `${fromTimeZone} to ${toTimeZone} time zone converter`;

    const cardTitle = props.fromTime ?
      `Convert ${formatTimeStrings(props.fromTime)} ${fromTimeZone} to ${toTimeZone}`
      : `Convert ${fromTimeZone} to ${toTimeZone}`;
  

    useEffect(() => {
      i18n.changeLanguage(props.language || 'en');
    }, []);


    return (
      <div className="App">
          <HomePageHead fromTimeZone={fromTimeZone} toTimeZone={toTimeZone} fromTime={props.fromTime} language={props.language || 'en'} />
          <Header />
          <div className='container' style={{ paddingBottom: 50 }}>
              <h1>{h1Content}</h1>
              <TimeZoneTableWithContext fromTZ={props.fromTimeZone} toTZ={props.toTimeZone} fromTime={props.fromTime} />
              <Row style={{ marginBottom: 15 }}>
                <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
                  <Card title={cardTitle} className='box'>
                    <p>
                      {t('headHomepage.convert.p1', {
                        fromTimeZone: fromTimeZone,
                        toTimeZone: toTimeZone,
                        fromTimeZoneDisplayName: fromTimeZoneDisplayName,
                        toTimeZoneDisplayName: toTimeZoneDisplayName,
                      })}
                    </p>
                    <p>
                      {t('headHomepage.convert.p2', {
                        fromTimeZone: fromTimeZone,
                        toTimeZone: toTimeZone,
                        fromTimeZoneDisplayName: fromTimeZoneDisplayName,
                        toTimeZoneDisplayName: toTimeZoneDisplayName,
                        fromTime: fromTime,
                        timeDiff: diff.diff,
                        aheadTimeZone: diff.aheadTZ,
                        convertedTime: '12:00 PM', // TODO: convert real time
                      })}
                    </p>
                    <p>
                      {t('headHomepage.convert.p3', {
                        fromTimeZone: fromTimeZone,
                        toTimeZone: toTimeZone
                      })}
                    </p>
                 </Card>
                </Col>
              </Row>
              <Row gutter={[16, 16]}>
                <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
                  <Card title={fromTimeZone} style={{width: '100%', height: '100%'}} className='box' >
                    <p>{t(`tz.${(fromTimeZone).toUpperCase()}.description`)}</p>
                  </Card>
                </Col>
                <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
                  <Card title={toTimeZone} style={{width: '100%', height: '100%' }} className='box' >
                    <p>{t(`tz.${(toTimeZone).toUpperCase()}.description`)}</p>
                  </Card>
                </Col>
              </Row>

          </div>
          <Footer />
      </div>
    );
}