import * as React from 'react';
import { AppContextProps, AppProps, HourMode, TimeZoneRowData } from './interface';
import { Chicago, Cities, London, Shanghai } from '../consts/city-list';
import { TimeZoneList } from '../consts/tz-list';
import { makeDefaultRowsBasedOnTZ } from './hooks/utils';

export const AppContext = React.createContext<AppContextProps>({
  hourMode: 'AMPM',
  setHourMode: null,
  timeZoneRows: [],
  removeRow: null,
  addRow: null,
  moveToTop: null,
  hasSelectedRange: false,
  setHasSelectedRange: null,
  leftIndex: -1,
  rightIndex: -1,
  setLeftIndex: (value: number) => {},
  setRightIndex: (value: number) => {},
  DST: true
});

export const defaultRow: TimeZoneRowData = {
  city: {...London},
  isDefault: true,
};

const defaultRows = [defaultRow, { city: Chicago}, { city: Shanghai}];

const setLocalStorage = (rows: TimeZoneRowData[]) => {
  localStorage.setItem('cityList', rows.map(row => row?.city?.cityName || row?.timezone?.displayName).join(',') || '');
}

export const getCityOrTimezone = (name: string): TimeZoneRowData | undefined => {
  const city = Cities.find(city => city.cityName === name);
  if (city) {
    return {city: {...city}} as TimeZoneRowData;
  }

  const timezone = TimeZoneList.find(tz => tz.displayName === name);
  if (timezone) {
    return {timezone: {...timezone}} as TimeZoneRowData;
  }

  return undefined;
}


const makeDefaultRows = (fromTZ?: string, toTZ?: string): TimeZoneRowData[] => {
  if (fromTZ && toTZ) {
    const TZDefault = makeDefaultRowsBasedOnTZ(fromTZ, toTZ);
    if (TZDefault.length > 0) {
      return TZDefault;
    }
  }

  const cityList = localStorage.getItem('cityList');
  if (!cityList) {
    return defaultRows;
  }

  const result = cityList.split(',')
    .map(cityOrTZName => getCityOrTimezone(cityOrTZName))
    .filter(ele => ele !== undefined) as TimeZoneRowData[];

  if (result.length > 0) {
    result[0].isDefault = true;
  }
  return result;
}

export const AppContextProvider: React.FC<React.PropsWithChildren<AppProps>> = ({ fromTZ, toTZ, fromTime, children }) => {
  const [hourMode, setHourMode] = React.useState<HourMode>('AMPM');
  const [hasSelectedRange, setHasSelectedRange] = React.useState<boolean>(false);
  const [left, setLeft] = React.useState<number>(-1);
  const [right, setRight] = React.useState<number>(-1);
  const [rows, setRows] = React.useState<TimeZoneRowData[]>(makeDefaultRows(fromTZ, toTZ));

  const removeRow = (index: number) => {
    const newRows = [...rows.slice(0, index), ...rows.slice(index + 1)]
    setLocalStorage(newRows);
    setRows(newRows);
  };

  const addRow = (row: TimeZoneRowData) => {
    const newRows = [...rows, row];
    setLocalStorage(newRows);
    setRows(newRows);
  };

  const moveToTop = (index: number) => {
    rows[0].isDefault = false;
    rows[index].isDefault = true;
    const newRows = [rows[index], ...rows.slice(0, index), ...rows.slice(index + 1)];
    setLocalStorage(newRows);
    setRows(newRows);
  };

  return (
    <AppContext.Provider value={{hourMode, setHourMode, timeZoneRows: rows, removeRow, addRow, moveToTop, hasSelectedRange, setHasSelectedRange, leftIndex: left, setLeftIndex: setLeft, rightIndex: right, setRightIndex: setRight, DST: true}} >
      { children }
    </AppContext.Provider>
  );
};
