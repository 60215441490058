import { useTranslation } from "react-i18next";
import { TimeZoneList } from "../../consts/tz-list";
import { TimeZoneRowData } from "../interface";

export const makeDefaultRowsBasedOnTZ = (fromTZ: string, toTZ: string): TimeZoneRowData[] => {
  const fromTZRow = TimeZoneList.filter(tz => tz.abbrev === fromTZ)?.[0];
  const toTZRow = TimeZoneList.filter(tz => tz.abbrev === toTZ)?.[0];
  if (fromTZRow && toTZRow) {
    return [{timezone: fromTZRow}, {timezone: toTZRow}];
  }
  return [];
}

export const useHourAndMinDiff = (fromTZ: string, toTZ: string, isDST: boolean): {aheadTZ: string, diff: string} => {
    const { t, i18n } = useTranslation();
  
    const rows = makeDefaultRowsBasedOnTZ(fromTZ, toTZ);
    const fromRowOffset = rows[0].city ? rows[0].city.UTCOffset : rows[0].timezone!.UTCOffset;
    const toRowOffset = rows[1].city ? rows[1].city.UTCOffset : rows[1].timezone!.UTCOffset;
    let hours = isDST ? toRowOffset.DST.hour - fromRowOffset.DST.hour : toRowOffset.STD.hour - fromRowOffset.STD.hour;
    let mins = isDST ? toRowOffset.DST.minute - fromRowOffset.DST.minute : toRowOffset.STD.minute - fromRowOffset.STD.minute;

    if (mins < 0 && hours > 0) {
        mins += 60;
        hours -= 1;
    }

    if (mins > 0 && hours < 0) {
        mins -= 60;
        hours += 1;
    }

    const aheadTZ = hours > 0 || mins > 0 ? toTZ : fromTZ;

    let diff = '';

    if (mins === 0) {
        diff = t('headHomepage.timeDiffH', {hours: Math.abs(hours)});
    }

    if (hours === 0) {
      diff = t('headHomepage.timeDiffM', {minutes: Math.abs(mins)});
    }


    if (hours !== 0 && mins !== 0) {
        diff = t('headHomepage.timeDiffHM', {hours: Math.abs(hours), minutes: Math.abs(mins)});
    }

    return {aheadTZ, diff}; 
}