/* eslint-disable no-unused-vars */
/* eslint-disable max-len */
import * as React from 'react';
import { CityTimezone, SelectedDateRange, CurrentTimeAndDate, PureTimezone } from './city-timezone';
import { SelectableArea } from './selectable-area';
import { TimeRuler } from './time-ruler';
import { ControlButtonsProps, TimeZoneRowProps, TimeRulerTableProps, UTCOffset, AppContextProps, AppProps } from './interface';
import * as Styles from './styles';
import { AppContext, AppContextProvider } from './context';
import { makeTimeZoneInfo } from './hooks/useDateTime';
import { SearchBar } from './search-bar';
import { ToTopOutlined, DeleteOutlined } from '@ant-design/icons';
import { max } from 'lodash';


const hourList = [
  0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20,
  21, 22, 23,
];

const ControlButtons: React.FC<ControlButtonsProps> = (props: ControlButtonsProps) => {
  return (
    <div style={{ 
        display: 'flex',
        flexDirection: 'column',
        width: Styles.ControlIconsWidth,
        height: Styles.HourHeight,
        justifyContent: 'flex-start',
        marginRight: Styles.ControlIconRightMargin,
        paddingTop: 4
    }}>
      {props.showMoveToTop && <ToTopOutlined onClick={props.moveToTop} style={{ marginBottom: 4 }} /> }
      {props.showRemove && <DeleteOutlined  onClick={props.remove} />}
    </div>
  );
};

const makeHourList = (hourList: number[], isDefault: boolean, DST: boolean, offset: UTCOffset, firstRowOffset: UTCOffset) => {
  const result = hourList.map((value) => {
    let startHour = 0;
    if (!isDefault) {
      if (DST) {
        startHour = offset.DST.hour - firstRowOffset.DST.hour;
      } else {
        startHour = offset.STD.hour - firstRowOffset.STD.hour;
      }
    }
    return (value + startHour + 24) % 24;
  })
  return result;
}


const TimeZoneRow: React.FC<TimeZoneRowProps> = (
  props: TimeZoneRowProps,
) => {
  const {hasSelectedRange, leftIndex, rightIndex, timeZoneRows, DST} = React.useContext(AppContext);
  const { isDefault, city, timezone } = props.rowData;

  const firstRowOffset = timeZoneRows[0].city ? timeZoneRows[0].city.UTCOffset : timeZoneRows[0].timezone!.UTCOffset;
  const hourListPermuted = makeHourList(hourList, !!isDefault, DST, city ? city.UTCOffset : timezone!.UTCOffset, firstRowOffset);

  const rowTZID = city ? city.tzID : timezone!.sampleTZID;
  const firstRowTZID = timeZoneRows[0].city ? timeZoneRows[0].city.tzID : timeZoneRows[0].timezone!.sampleTZID;

  return (
    <div style={{ display: 'flex', height: Styles.HourHeight }}>
      <ControlButtons showRemove showMoveToTop={props.index !== 0} remove={props.remove} moveToTop={props.moveToTop} />
      {city && <CityTimezone 
        cityName={city.cityName}
        countryName={city.country}
        timezoneName={city.tzID}
        tzAbbr={DST ? city.tzAbbr.DST : city.tzAbbr.STD} 
        offset={DST ? city.UTCOffset.DST.hour : city.UTCOffset.STD.hour}
      />}
      {timezone && <PureTimezone tzAbbrev={timezone.abbrev} displayTime={timezone.displayName} />}
      <div style={{ width: Styles.CurrentTimeInfoWidth, marginRight: Styles.CurrentTimeInfoMarginRight }}>
        {
          hasSelectedRange ? 
          <SelectedDateRange 
            left={makeTimeZoneInfo('en-US', firstRowTZID, rowTZID, leftIndex)} 
            right={makeTimeZoneInfo('en-US', firstRowTZID, rowTZID, rightIndex)} 
          />
          :
          <CurrentTimeAndDate timezone={rowTZID} />
        }
      </div>

      <TimeRuler hourList={hourListPermuted} timezone={rowTZID} />
    </div>
  );
};

const TimeZoneTable: React.FC<TimeRulerTableProps> = (
  props: TimeRulerTableProps,
) => {
  const { timeZoneRows: rows, removeRow, moveToTop } = React.useContext(AppContext);

  return (
    <div style={{ height: max([rows.length*44 + 100, 300]) }}>
      <div style={{ position: 'absolute', paddingTop: Styles.RowGap}}>
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          { 
          rows.map((row, index) => 
            <>
              <TimeZoneRow 
                rowData={row}
                index={index}
                remove={()=>{removeRow?.(index);}}
                moveToTop={()=>{moveToTop?.(index)}}
              />
              <div style={{ height: Styles.RowGap}}></div>
            </>
            )
          }
        </div>
        <SelectableArea width={Styles.HourWidth * 24} height={Styles.HourHeight * rows.length + Styles.RowGap*(rows.length+1)} selectableUnitWidth={Styles.HourWidth / 2} />
      </div>
    </div>
  );
};

export const TimeZoneTableWithContext = (props: AppProps) => {
  return (
    <AppContextProvider {...props}>
      <div className='box' style={{ padding: 20, marginTop: 20, marginBottom: 20 }}>
        <SearchBar />
        <TimeZoneTable />
      </div>
    </AppContextProvider>
  );
}